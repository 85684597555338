<template>
  <b-dropdown 
    no-caret
    :right="right_position"
    class="dropdown-color dropdown-color-secondary"
    :disabled="disabled"
    @hide="closedDropdown"
  >
    <template #button-content>
      <b-button
        variant="outline-secondary" 
        :class="`outline-secondary-hover ${padding_custom}`"
        :disabled="disabled"
      >
        {{$t(text_button)}}
      </b-button>
    </template>
    <b-dropdown-header>
      <h6>{{$t('search.add_to_campaign')}}</h6>
      <hr class="m-0 mb-1">
      <b-col 
        class="col-12 dropitem-add-campaign"
        :class="checkClassesInCampaign(campaign)"
        v-for="(campaign, index) in campaigns" 
        :key="index" 
        @click="disabled ? toastSelectInfluencer() : handleCheck(campaign.value, index)"
        :ref="`disable-campaign-${index}`"
      >
        <div class="d-flex justify-content-between container-drop-items">
          <span class="d-block avenir-medium"> {{campaign.text.substr(0, 25)}}</span>

          <feather-icon
            v-if="influencers_in.length > 0"
            :icon="checkInCampaign(campaign.value) ? 'CheckCircleIcon' : 'PlusIcon'" 
            :class="checkInCampaign(campaign.value) ? 'success-color-icon' : 'secondary-color-icon'"
            :ref="`icon-check-${index}`"  
          >
          </feather-icon>
          <span v-else>
            <feather-icon icon="PlusIcon" :ref="`plus-icon-list-${index}`" class="icons-absolute-status"></feather-icon>
            <feather-icon icon="CheckCircleIcon" class="d-none icons-absolute-status success-color-icon" :ref="`check-icon-list-${index}`"></feather-icon>
          </span>
          <b-spinner class="spinner-dropdown-item d-none" :ref="`spinner-dropdown-c-${index}`"></b-spinner>
        </div>
      </b-col>
      <div v-if="campaigns.length === 0" class="avenir-medium">
        {{ $t('campaigns.noCampaigns') }}
      </div>
      <hr>
      <b-link target="_blank" href="/campaigns" class="d-flex align-items-center color-link"><feather-icon icon="ExternalLinkIcon" class="margin-right-05"></feather-icon> {{$t('search.go_to_campaign')}}</b-link>
    </b-dropdown-header>
  </b-dropdown>
</template>

<script>
import {
  BDropdown, BDropdownHeader, BSpinner, BLink, BCol, BButton
} from 'bootstrap-vue'
import service_campaign from '@/services/campaign'
import service_profile from '@/services/others';
import outofhome_service from '@/services/outofhome';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'dropdownCampaigns',
  components: {
    BDropdown,
    BDropdownHeader,
    BSpinner,
    BLink,
    BCol,
    BButton
  },
  props: {
    campaigns: {
      type: Array,
      require: () => [],
    },
    influencers: {
      type: Array,
      default: () => []
    },
    campaigns_influencer: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: true
    },
    multiple_added: {
      type: Boolean,
      default: false
    },
    is_ooh: {
      type: Boolean,
      default: false
    },
    text_button: {
      type: String,
      default: 'search.campaign'
    },
    padding_custom: {
      type: String,
      default: 'padding-default'
    },
    right_position: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected_campaign: '',
      influencers_in: this.campaigns_influencer,
    }
  },
  methods: {
    checkInCampaign(campaign_uuid) {
      if (this.influencers_in.length > 0) {
        const already_campaign = this.influencers_in.find(campaign => campaign.uuid === campaign_uuid)
        if (already_campaign && (!this.is_ooh) ? already_campaign.with_influencer : true) return true
        else return false
      }
    },
    checkClassesInCampaign(campaign) {
      const in_campaign = this.checkInCampaign(campaign.value)
      if (in_campaign) return 'dropitem-add-campaign-disabled'
      else return ''
    },
    toastSelectInfluencer() {
      loadToastificationContent().then((module) => {
        this.$toast({
          component: module.default,
          position: "top-right",
          props: {
            title:  this.$t('search.check_influencer_title'),
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: this.$t('search.check_influencer_text')
          },
        });
      })
    },
    handleCheck(campaign_uuid = null, index_spinner = null) {
      const already_campaign = this.influencers_in.find(cam => cam.uuid === campaign_uuid)
      if (already_campaign && already_campaign.with_influencer) return
      this.selected_campaign = campaign_uuid;
      this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.remove('d-none');
      if (this.influencers_in.length === 0) this.$refs[`plus-icon-list-${index_spinner}`][0].classList.add('d-none');
      (this.is_ooh) ? this.addLocations(index_spinner) : this.addCheckedInfluencer(index_spinner);
    },
    addCheckedInfluencer(index_spinner) {
      service_campaign.addInfluencersCampaign(this.selected_campaign, this.influencers)
        .then(() => {
          const already_campaign = this.influencers_in.find(campaign => campaign.uuid === this.selected_campaign)
          if (already_campaign) already_campaign.with_influencer = true
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: this.$t('search.added'),
                icon: 'CheckCircleIcon',
                text: this.$t('search.hasBeenAdded'),
                variant: 'success'
              },
            });
          })
          this.resetIconsList(index_spinner);
          this.$emit('addedCampaign');
          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            });
        })
    },
    addLocations(index_spinner) {
      outofhome_service.toggleLocation(this.selected_campaign, this.influencers, true).then(() => {
        const titles = this.influencers.length > 1 ? this.$t('search.realty.addSpaces') : this.$t('addSpace')
        const texts = this.influencers.length > 1 ? this.$t('search.realty.addedLocations') : this.$t('search.realty.addedLocation')
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: titles,
              icon: 'BellIcon',
              text: texts,
              variant: 'success'
            },
          });
        })
        this.resetIconsList(index_spinner);
      });
    },
    resetIconsList(index_spinner) {
      setTimeout(() => {
        this.$refs[`spinner-dropdown-c-${index_spinner}`][0].classList.add('d-none');
        this.$refs[`disable-campaign-${index_spinner}`][0].classList.add('dropitem-add-campaign-disabled');
        if (this.influencers_in.length === 0) {
          this.$refs[`plus-icon-list-${index_spinner}`][0].classList.add('d-none');
          this.$refs[`check-icon-list-${index_spinner}`][0].classList.remove('d-none');
        }
        this.selected_campaign = null;
      }, 500);
    },
    closedDropdown() {
      if (this.influencers_in.length === 0) {
        for (let i = 0; i < this.campaigns.length; i++) {
          this.$refs[`plus-icon-list-${i}`][0].classList.remove('d-none');
          this.$refs[`check-icon-list-${i}`][0].classList.add('d-none');
          this.$refs[`disable-campaign-${i}`][0].classList.remove('dropitem-add-campaign-disabled');
        }
      }
      if (this.is_ooh) this.$emit('update_drop');
    },
  }
}
</script>

<style>
.padding-default {
  padding: 0.786rem 1.5rem;
}
.dropitem-add-campaign {
  width: 280px;
  padding: 0.5em;
}
.dropitem-add-campaign:hover {
  background-color: #EFF3F6;
  cursor: pointer;
}
.dropitem-add-campaign-disabled {
  color: #ccc;
}
.dropitem-add-campaign-disabled:hover {
  background-color: transparent;
  cursor: default;
}
.spinner-dropdown-item {
  position: absolute;
  right: 21px;
  background-color: white;
  height: 16px;
  width: 16px;
  color: #ccc;
}
.dropdown-color > button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.dropdown-color > .dropdown-menu {
  margin-top: 5px !important;
}
.color-link {
  color: #7367f0 !important;
}
.success-color-icon {
  color: green;
}
.outline-secondary-hover {
  border-color: #82868b;
  color: #82868b;
}
.outline-secondary-hover:hover {
  color: #82868b;
  background-color: white;
}
.dropdown-color-secondary > .dropdown-toggle-no-caret:active, .dropdown-color-secondary > .dropdown-toggle-no-caret:focus {
  color: #82868b;
  background-color: white !important;
}
.icons-absolute-status {
  position: absolute;
  right: 9px !important;
}
</style>
