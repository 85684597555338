import config from '@/services/config'
import axios from './axios_interceptors'
import { tokenExpired } from '@/auth/utils'

export default {
  googleLogin(formData) {
    return axios.post(`${config.urlCore}/api/v1/accounts/google-login/`, formData)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  setRol(formData) {
    return axios.post(`${config.urlCore}/api/v1/accounts/define-role/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  createCompany(formData) {
    return axios.post(`${config.urlCore}/api/v1/accounts/account-company/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  formCreator(formData) {
    return axios.put(`${config.urlCore}/api/v1/accounts/profile/influencer/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getProfile() {
    return axios.get(`${config.urlCore}/api/v1/accounts/profile/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getProfileByVanityName(vanity_name) {
    return axios.get(`${config.urlCore}/api/v3/profile/${vanity_name}`)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updateProfile(formData) {
    return axios.put(`${config.urlCore}/api/v1/accounts/profile/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getHashtags(hashtag) {
    return axios.get(`${config.urlCore}/api/v3/management/hastags/?tag=${hashtag}`,
      {
        headers: {}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getcontents() {
    return axios.get(`${config.urlCore}/api/v3/monitor/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getDefaultKeyword() {
    return axios.get(`${config.urlCore}/api/v3/monitor/default-keyword/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getImage(url) {
    return axios.get(`${config.urlCore}${url}`,
      { responseType: "arraybuffer" })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updatePassword(formData) {
    return axios.put(`${config.urlCore}/api/v1/accounts/password/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  sendEmailPass(formData, session) {
    if (session) return axios.post(`${config.urlCore}/api/v1/accounts/send-password/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
    return axios.post(`${config.urlCore}/api/v1/accounts/send-password/`,
      formData)
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getSocialNetworks(params = '') {
    return axios.get(`${config.urlCore}/api/v1/accounts/profile/networks/?${params}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addSocialNetwork(formData) {
    return axios.post(`${config.urlCore}/api/v1/accounts/profile/networks/add/`,
      formData,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },
  removeSocialNetwork(uuid) {
    return axios.post(`${config.urlCore}/api/v1/accounts/profile/networks/remove/${uuid}/`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  convertPremium(money, some_text) {
    return axios.get(`${config.urlCore}/api/v1/accounts/profile/premium/?money=${money}&textbody=${some_text}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getBrands() {
    return axios.get(`${config.urlCore}/api/v1/brands/`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addBrand(name) {
    return axios.post(`${config.urlCore}/api/v1/brands/`,
      name,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => {
        response.data.response;
      })
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  removeBrand(uuid) {
    return axios.post(`${config.urlCore}/api/v1/brands//${uuid}/`,
      {},
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  addInterests(interest) {
    return axios.post(`${config.urlCore}/api/v1/accounts/add-interest/`, interest, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  removeInterests(uuid) {
    return axios.delete(`${config.urlCore}/api/v1/accounts/delete-interest/${uuid}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  addBriefcase(form) {
    return axios.post(`${config.urlCore}/api/v1/briefcase/`, form, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },

  getOwnBriefcase() {
    return axios.get(`${config.urlCore}/api/v1/briefcase/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },

  getBriefcase(network, username) {
    return axios.get(`${config.urlCore}/api/v2/weaver/collaborations/${network}/${username}/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },

  removeBriefcase(uuid) {
    return axios.delete(`${config.urlCore}/api/v1/briefcase/${uuid}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },

  addPricing(form) {
    return axios.post(`${config.urlCore}/api/v1/briefcase/pricing/`, form, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },

  
  deletePricing(uuid) {
    return axios.delete(`${config.urlCore}/api/v1/briefcase/pricing/${uuid}/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  getOwnPricing() {
    return axios.get(`${config.urlCore}/api/v1/briefcase/pricing/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  getPricing(network, username) {
    return axios.get(`${config.urlCore}/api/v2/weaver/pricing/${network}/${username}/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  getInterest(network, username) {
    return axios.get(`${config.urlCore}/api/v2/weaver/interests/${network}/${username}/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      })
  },
  getVisitedProfiles(network = '', username = '', size_page = 20, page = 1) {
    return axios.get(`${config.urlCore}/api/v1/finder/searches/?network=${network}&username=${username}&size_page=${size_page}&page=${page}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response
      });
  },
  addEmailPaypal(data) {
    return axios.post(`${config.urlCore}/api/v3/finance/save/paypal/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  transactionsWithFilters(data = {}, size_page = 20, page = 1) {
    return axios.post(`${config.urlCore}/api/v3/transactions/?size_page=${size_page}&page=${page}`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  deleteAccountBrandme(data) {
    return axios.post(`${config.urlCore}/api/v1/accounts/delete-account/`,
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  getMaxViews() {
    return axios.get(`${config.urlCore}/api/v1/accounts/max-views/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        console.log(error)
        return error.response.data.response
      });
  },
  updateMainBrand(data, brand_uuid) {
    return axios.post(`${config.urlCore}/api/v3/casting-calls/edit-brand/${brand_uuid}/`, data, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        return error.response
      })
  },
  updateMetricsUserNetwork(user_network_uuid) {
    return axios.get(`${config.urlCore}/api/v3/social/update/info/${user_network_uuid}/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
      .then(response => response.data.response)
      .catch(error => {
        return error.response.data.response
      });
  },
  buyCreditsPackage(data) {
    return axios.post(`${config.urlCore}/api/v3/profile/buy/extra-credits/`, 
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response.data.response
      });
  },
  applyTrial(data) {
    return axios.get(`${config.urlCore}/api/v3/plans/apply-trial/`, 
      data,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        return error.response.data.response
      });
  }
}
