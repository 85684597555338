<template>
  <component :is="tag" v-on="$listeners">
    <div :class="`${padding_component} ${user.type === 'null' ? 'without-badge' : ''} d-flex align-items-center`">
      <b-avatar :src="user.avatar_url" badge-variant="ligth" size="42">
        <template #badge>
          <b-avatar size="18px" class="avatar-absolute" variant="ligth">
            <b-img :src="getIconWhite(user.type)" :class="getIconColor(user.type, false)" fluid/>
          </b-avatar>
        </template>
      </b-avatar>
      <div class="chat-info flex-grow-1">
        <h5 class="mb-0 pl-1">
          {{ user.title }}
          <image-verified
            v-if="user.is_verified"
          />
        </h5>
        <p class="card-text text-truncate pl-1">
          {{ getFormat(user.subscribers_count) }}
        </p>
      </div>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge, BImg } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import useChat from './useChat';
import { getFormat } from '@/libs/utils/formats';
import { getIconWhite, getIconColor } from '@/libs/utils/icons'

export default {
  components: {
    BImg,
    BAvatar,
    BBadge,
    ImageVerified: () => import('@/views/components/image/ImageVerified.vue'),
  },
  data() {
    return {
      getIconColor,
      getIconWhite,
      getFormat,
    };
  },
  props: {
    padding_component: {
      type: String,
      default: 'pb-1',
    },
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat();
    return { formatDateToMonthShort, resolveAvatarBadgeVariant };
  },
};
</script>

<style scoped>
.avatar-absolute {
  position: absolute;
  left:4px
}
.without-badge > .b-avatar {
  background-color: #fff
}
</style>
