import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'
export default {

  getCampaign () {
    return axios.get(`${config.urlCore}/api/v1/campaign/`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  getTotalCampaigns() {
    return axios.get(`${config.urlCore}/api/v3/campaign/total-campaigns`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updateCampaign (uuid, formData) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${uuid}/update/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response.results)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },

  getBanners () {
    return axios.get(`${config.urlCore}/api/v3/management/banners/`,
      {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}})
      .then(response => response.data.response.results)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },

  getBalance() {
    return axios.get(`${config.urlCore}/api/v3/finance/balance/`,
      {headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}})
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response.data.response
      })
  },

  getCore: config.urlCore

}
