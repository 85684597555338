<template>
  <b-sidebar
    :sidebar-class="`${changed_sidebar ? 'sidebar-animation' : ''}`"
    bg-variant="white"
    right
    backdrop
    shadow
    width="500px"
    v-model="show"
    @change="changedSidebar"
  >
    <b-overlay
      :show="addInfluencers"
      rounded="sm"
    >
      <b-card>
        <b-row class="match-height">
          <b-col
            md="12"
          >
            <h2>{{$t('campaigns.add_influencer')}}</h2>
            <small>{{$t('campaigns.addInfluencer-description')}}</small>
          </b-col>
          <b-col
            md="12"
            class="mt-1"
          >
          <b-tabs pills v-model="active_tab" :active-nav-item-class="`color-tab-${network_tab}`">
                <b-tab
                v-on:click="changeProfile('')"
                :title= "$t('campaigns.all')"
                active
                >
                </b-tab>
                <b-tab
                v-on:click="changeProfile('instagram')"
                nav-class="nav-pill-danger"
                >
                  <template #title>
                    <feather-icon class="margin-icons" icon="InstagramIcon" size="16"/>
                  </template>
                </b-tab>
                <b-tab
                v-on:click="changeProfile('youtube')"
                >
                  <template #title>
                    <feather-icon class="margin-icons" icon="YoutubeIcon" size="20"/>
                  </template>                       
                </b-tab>
                <b-tab
                v-on:click="changeProfile('tiktok')"
                >
                  <template #title>
                    <span :class="`icomoon-tiktok tiktokIconAnalize span-tiktok-icon`" v-if="active_tab === 3"></span>
                    <span :class="`icomoon-tiktok tiktokIconAnalize`" v-else></span>              
                  </template>               
                </b-tab>
                <b-tab
                v-on:click="changeProfile('twitch')"
                >
                  <template #title>
                    <feather-icon class="margin-icons" icon="TwitchIcon" size="20"/>
                  </template>             
                </b-tab>
                <b-tab
                v-on:click="changeProfile('twitter')"
                >
                  <template #title>
                    <b-img src="@/assets/images/icons/twitter-x-black.svg" class="network-x-tabs"/>  
                  </template>   
                </b-tab>
            </b-tabs>
          </b-col>
          <b-col
            md="12"
            class="mb-1"
            style="position: relative"
          >
            <b-spinner
              v-show="loading"
              variant="primary"
              label="Text Centered"
              style="position: absolute; right: 30px; top: 5px"
            />
            <vue-autosuggest
            v-model="query"
            :suggestions="filteredOptions"
            :input-props="inputProps"
            :limit="10"
            @input="handleInput"
            @click="resetQuery"
            @selected="selectInfluencer"
            :get-suggestion-value="getSuggestionValue"
            class="cursor-pointer"
            ref="inputSearch"
            :should-render-suggestions="shouldRenderSuggestions"
          >
          <template slot-scope="{suggestion}">
            <chat-contact
                class="p-0"
                :padding_component="'p-0'"
                :user="suggestion.item"
                tag="li"
                is-chat-contact
              />
          </template>
          <template slot="before-suggestions">
            <div 
              class="m-1"
              v-if="query !== ''"
            >
              {{$t('search.searchingBy')}}: {{query}}
            </div>
            <hr class="m-0" v-if="query !== ''">
          </template>
          <template slot="before-suggestions">
            <div 
              class="m-1"
              v-if="no_suggestions"
            >
            {{$t('lists.noresults')}} {{query}}

            </div>
          </template>
          </vue-autosuggest>
          </b-col>
          <div class="col-12 mb-1 list-influencer-add">
            <b-list-group v-if="selected !== null" class="col-12">
              <b-list-group-item v-for="(item, index) in selected" :key="index">
                <div class="d-flex align-items-center">
                  <b-avatar :src="item.avatar_url" badge-variant="ligth" size="42">
                    <template #badge>
                      <b-avatar size="18px" class="avatar-absolute" variant="ligth">
                        <b-img :src="getIconWhite(item.type)" :class="getIconColor(item.type, false)" fluid/>
                      </b-avatar>
                    </template>
                  </b-avatar>
                  <div class="chat-info flex-grow-1">
                    <h5 class="mb-0 pl-1">
                      {{ item.title }}
                      <image-verified
                        v-if="item.is_verified"
                        class="is-verified"
                      />
                    </h5>
                    <p class="card-text text-truncate pl-1">
                      {{ getFormat(item.subscribers_count) }}
                    </p>
                  </div>

                  <feather-icon class="cursor-pointer" icon="XIcon" @click="deleteFromList(item.user_id)"></feather-icon>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
          <b-col
            lg="12"
          >
            <b-button
              class="blue-button"
              variant="blue-button"            
              :disabled="disabledUpdateCampaign() || selected.length === 0"
              @click="addInfoInfluencer()"
            >
            {{$t('lists.add')}}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </b-sidebar>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTab, BTabs, BOverlay, BSpinner, BAvatar, BListGroup, BListGroupItem, BSidebar, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import campaignService from '@/services/campaign'
import { VueAutosuggest } from 'vue-autosuggest'
import ChatContact from '@/views/apps/chat/ChatContact.vue'
import axios from 'axios'
import config from '@/services/config'
import service_profile from '@/services/others'
import ImageVerified from '@/views/components/image/ImageVerified.vue';
import { getIconWhite, getIconColor } from '@/libs/utils/icons'
import { getFormat, trimText } from '@/libs/utils/formats';
import { hasPermission } from '@/libs/utils/user';

export default {
  components: {
    BImg,
    ImageVerified,
    BRow,
    BCol,
    BCard,
    BButton,
    BTab,
    BTabs,
    BOverlay,
    BSpinner,
    VueAutosuggest,
    ChatContact,
    BAvatar,
    BListGroup,
    BListGroupItem,
    BSidebar,
  },
  directives: {
    Ripple
  },
  data () {
    return {
      hasPermission,
      trimText,
      getFormat,
      getIconWhite,
      getIconColor,
      addInfluencers: false,
      typingTimer: null,
      query: '',
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: this.$t('lists.searchInfluencer')
      },
      selected: [],
      loading: false,
      cancel: null,
      no_suggestions: false,
      active_tab: 0,
      network_tab: null,
      influencersAdd: [],
      show: this.show_side_influencer,
      changed_sidebar: true,
    }
  },
  props: {
    list_service: {
      type: Boolean,
      default: false
    },
    show_side_influencer: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    changedSidebar(val) {
      this.changed_sidebar = val;
    },
    deleteFromList(user_id) {
      this.selected = this.selected.filter((item) => item.user_id !== user_id);
    },
    engagement (num) {
      let result = ''
      if (num > 999) {
        result = `${(Math.round(num / 100) / 10)} K`
      }
      if (num > 999999) {
        result = `${(Math.round(num / 100000) / 10)} M`
      }
      if (num > 999999999) {
        result = `${(Math.round(num / 100000000) / 10)} B`
      }
      return result
    },
    changeProfile (red) {
      this.localValue = red
      this.query = ''
    },
    resetQuery () {
      if (this.query === '') {
        this.inputProps.class = 'form-control'
        this.filteredOptions = []
      }
    },
    handleInput() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.getResults, 300); //300 ms o el tiempo que se necesite
    },
    getResults () {
      this.filteredOptions = []
      this.no_suggestions = false;
      if (this.query !== '') {
        this.loading = true;
        const CancelToken = axios.CancelToken;

        if (this.cancel) this.cancel()

        const self = this;
        axios({
          method: 'post',
          url: `${config.urlCore}/api/v1/finder/suggester/`,
          data: {
            query: trimText(this.query),
            networks: this.localValue,
          },
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,

          },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            self.cancel = c;
          })
        })
          .then(res => {
            self.filteredOptions = [{data: res.data.response.list}]
            self.no_suggestions = res.data.response.list.length === 0
            self.loading = false;
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('');
            } else {
              self.no_suggestions = true;
              self.loading = false;
            }
          });
      }
    },
    selectInfluencer (info) {
      let value = '';
      info.item.type === 'youtube' ? value = 'channel_id' : value = 'user_id';
      if (this.selected.findIndex((item) => item[value] === info.item[value]) === -1) {
        this.selected.unshift(info.item);
        this.influencersAdd.push({
          influencer: info.item.username,
          username: info.item.username, 
          network: info.item.type, 
          image: info.item.avatar_url, 
          followers: info.item.subscribers_count, 
          is_verified: info.item.is_verified, 
          channel_id: info.item.channel_id
        });
      }
      this.query = info.item.username
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.username
    },
    shouldRenderSuggestions (size, loading) {
      return size >= 0 && !loading
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
    addInfoInfluencer () {
      if (this.query !== '') {
        if (this.disabledUpdateCampaign()) return;

        if (this.list_service) {
          this.listService();
        } else {
          this.campaignService();
        }
        this.show = false;
      }
    },
    listService() {
      this.$emit('add_influencer', {influencers: this.influencersAdd});
    },
    campaignService() {
      campaignService.addInfluencersCampaign(this.$route.params.uuid, this.influencersAdd).then(e => {
        this.$emit('update_header', e.campaign_header);
        if (e.status === 400) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-left',
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            })
          })
        } else if (e.status === 500) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-left',
              props: {
                title: this.$t('campaigns.errorInfluencer'),
                icon: 'BellIcon',
                text: e.data.response.message,
                variant: 'danger'
              }
            })
          })
        } else {
          this.query = '';
          this.resetQuery();
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-left',
              props: {
                title: this.$t('campaigns.addedInfluencer'),
                icon: 'CheckCircleIcon',
                text: this.$t('campaigns.addSuccess'),
                variant: 'success'
              }
            })
          })
          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        }
        this.addInfluencers = false
        this.$emit('childPost')
      }).catch(
        this.addInfluencers = false
      )
    }
  },
  watch: {
    '$i18n.locale'() {
      this.inputProps.placeholder = this.$t('lists.searchInfluencer')
    },
    active_tab(val) {
      if (val === 0)
        this.network_tab = 'null';
      else if (val === 1) 
        this.network_tab = 'instagram';
      else if (val === 2)
        this.network_tab = 'youtube';
      else if (val === 3)
        this.network_tab = 'tiktok';
      else if (val === 4)
        this.network_tab = 'twitch';
      else if (val === 5)
        this.network_tab = 'twitter';
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style>
  .list-influencer-add {
    max-height: 23em !important;
    overflow: hidden;
    overflow-y: scroll;
  }
  .color-tab-instagram {
    color: rgb(237, 74, 96) !important;
    background-color: white !important;
    border: 1px solid rgb(237, 74, 96) !important;
    box-shadow: rgba(237, 74, 96, 0.2) 0px 7px 29px 0px !important;
  }
  .color-tab-youtube {
    align-self: right !important;
    color: rgb(255, 5, 29) !important;
    background-color: white !important;
    border: 1px solid rgb(255, 5, 29) !important;
    box-shadow: rgba(255, 5, 29, 0.2) 0px 7px 29px 0px !important;  
  }
  .color-tab-tiktok {
    color: rgb(255, 6, 83) !important;
    background-color: white !important;
    border: 1px solid rgb(255, 6, 83) !important;        
    box-shadow: rgba(255, 6, 83, 0.2) 0px 7px 29px 0px !important;  
  }
  .color-tab-twitch {
    color: rgb(148, 73, 250) !important;
    background-color: white !important;
    border: 1px solid rgb(148, 73, 250) !important;
    box-shadow: rgba(148, 73, 250, 0.2) 0px 7px 29px 0px !important;  
  }
  .color-tab-twitter {
    background-color: transparent !important;
    border: 1px solid black !important;
    box-shadow: rgba(58, 58, 59, 0.171) 0px 7px 29px 0px !important;  
  }
  .color-tab-null {
    background-color: #7367F0 !important;
  }
  .span-tiktok-icon {
    color: rgb(255, 6, 83) !important;
  }
</style>

<style scoped>
  .margin-icons {
    margin: 0px !important;
  }
  .avatar-absolute {
    position: absolute;
    left:4px
  }
  .network-x-tabs {
    color: black !important;
    width: 1.2em !important;
  }
  .radio label {
    position: relative;
    cursor: pointer;
    float: left;
    line-height: 40px;
    margin-bottom:0 !important;
  }
  .radio input {
    position: absolute;
    opacity: 0;
  }
  .radio input:checked ~ .radio-control {
    border-bottom: 1px solid red;
    color: red;
    margin-bottom:4px;
  }
  .autosuggest__results {
    margin-top: 0px !important;
  }
  .tiktokIconAnalize {
  font-size: 1.1em;
  color: #444
  }
</style>
