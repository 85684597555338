import { capitalize } from '@/libs/utils/formats'

export const getDate = unix => {
  const fecha = new Date(unix * 1000);
  return fecha.getTime();
};

export const getDatev2 = date => {
  const month = date.getMonth() + 1;
  const string_month = month < 10 ? `0${month}` : `${month}`;
  return `${date.getDate()}/${string_month}/${date.getFullYear()}`;
};

export const getDatev3 = date => {
  const newDate = new Date(date);
  const options = { hour: 'numeric', minute: 'numeric', year: 'numeric', month: 'long', day: 'numeric' };
  return newDate.toLocaleDateString('es-ES', options);
};

export const getDatev4 = date => {
  const month = date.getMonth() + 1;
  const string_month = month < 10 ? `0${month}` : `${month}`;
  return `${date.getFullYear()}-${string_month}-${date.getDate()}`;
};
export const daysUntil = (date_string) => {
  const now = new Date();
  const target_date = new Date(date_string);
  const difference = target_date - now;
  const days_difference = Math.ceil(difference / (1000 * 60 * 60 * 24));
  return days_difference;
}

export const getFormatTimeDifference = (old_date) => {
  const time_difference = new Date() - new Date(old_date);
  const minute = 60 * 1000;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;
  if (time_difference < minute || isNaN(time_difference)) {
    return "Justo ahora";
  } else if (time_difference < hour) {
    const diff = Math.round(time_difference / minute);
    return `Hace ${diff} ${diff === 1 ? "minuto" : "minutos"}`;
  } else if (time_difference < day) {
    const diff = Math.round(time_difference / hour);
    return `Hace ${diff} ${diff === 1 ? "hora" : "horas"}`;
  } else if (time_difference < week) {
    const diff = Math.round(time_difference / day);
    return `Hace ${diff} ${diff === 1 ? "día" : "días"}`;
  } else if (time_difference < month) {
    const diff = Math.round(time_difference / week);
    return `Hace ${diff} ${diff === 1 ? "semana" : "semanas"}`;
  } else if (time_difference < year) {
    const diff = Math.round(time_difference / month);
    return `Hace ${diff} ${diff === 1 ? "mes" : "meses"}`;
  } else {
    const diff = Math.round(time_difference / year);
    return `Hace ${diff} ${diff === 1 ? "año" : "años"}`;
  }
};

export const getFormatTimeDifferenceChat = (old_date) => {
  const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
  const difference = new Date() - new Date(old_date);
  const difference_days = Math.floor(difference / (24 * 60 * 60 * 1000));

  if (difference_days === 0) {
    return 'Hoy';
  } else if (difference_days === 1) {
    return 'Ayer';
  } else if (difference_days === 2) {
    return 'Antier';
  } else if (difference_days < 7) {
    return `El ${days[new Date(old_date).getDay()]}`;
  } else {
    return getDatev2(new Date(old_date))
  }
};

export const getHourMinutes = (date) => {
  const newDate = new Date(date);
  const withPmAm = newDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return withPmAm
};
export const formatDuration = (duration_string) => {
  let a = duration_string.match(/\d+/g);
  if (duration_string.indexOf('M') >= 0 && duration_string.indexOf('H') === -1 && duration_string.indexOf('S') === -1) {
    a = [0, a[0], 0];
  }

  if (duration_string.indexOf('H') >= 0 && duration_string.indexOf('M') === -1) {
    a = [a[0], 0, a[1]];
  }
  if (duration_string.indexOf('H') >= 0 && duration_string.indexOf('M') === -1 && duration_string.indexOf('S') === -1) {
    a = [a[0], 0, 0];
  }

  let duration = 0;

  if (a.length === 3) {
    duration = duration + (parseInt(a[0]) * 3600);
    duration = duration + (parseInt(a[1]) * 60);
    duration = duration + (parseInt(a[2]));
  }

  if (a.length === 2) {
    duration = duration + (parseInt(a[0]) * 60);
    duration = duration + (parseInt(a[1]));
  }

  if (a.length === 1) {
    duration = duration + (parseInt(a[0]));
  }

  return ((duration - (duration %= 60)) / 60) + (9 < duration ? ':' : ':0') + duration
};

export const formatDateIA = (date, locale = 'es') => {
  const monthNameLong = date.toLocaleString(locale === 'es' ? 'es-MX' : 'en-US', {month: 'long'});
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedTime = `${hours % 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  return `${capitalize(monthNameLong)} ${day}, ${year} ${locale === 'es' ? 'a las' : 'at'} ${formattedTime}`;
};
export const getDatev2original = (date) => {
  // eslint-disable-next-line no-mixed-operators
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  const month = utcDate.getUTCMonth() + 1;
  const string_month = month < 10 ? `0${month}` : `${month}`;

  return `${utcDate.getUTCDate()}/${string_month}/${utcDate.getUTCFullYear()}`;
};