<template>
  <b-img class="check-verified" src="@/assets/images/elements/verified.png"/>
</template>
  
<script>
import { BImg } from 'bootstrap-vue';
export default {
  name: "ImageVerified",
  components: {
    BImg
  },
};
</script>
<style>
.check-verified {
  width: 15px;
  height: 15px;
}
</style>